// https://material.io/archive/guidelines/style/color.html#color-color-palette
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;

@include mat.core();

$quipu-primary: mat.define-palette(mat.$blue-palette, A700, A400, A200);
$quipu-accent: mat.define-palette(mat.$gray-palette, 900, 700, 500);
$quipu-warn: mat.define-palette(mat.$red-palette, 700, 600, 500);


$quipu-typography: mat.define-typography-config(
  $font-family: var(--IBMPlexSans),
);

$quipu-theme: mat.define-light-theme((
  color: (
    primary: $quipu-primary,
    accent: $quipu-accent,
    warn: $quipu-warn,
  ),
  typography: $quipu-typography
));
//@include mat.core($quipu-theme);
@include mat.all-component-themes($quipu-theme);




// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
