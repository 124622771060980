mat-dialog-content{
  padding-top: 10px;
}

.input-captcha{
  font-size: 18px;
  color: var(--primary);
  text-align: center;
}

.label-captcha{
  font-size: 26px;
  color: #212121;
  text-align: center;
}

.actions-dialog{
  padding-top: 40px;
}

.title-tipo1{
  padding-left: 10px;
  padding-top: 2px;
  font-size: 24px;
}
.title-tipo2{
  padding-left: 10px;
  padding-top: 5px;
  font-size: 14px;
}
.title-number{
  font-size: 48px;
}

.mat-divider-content{
  margin-top: 20px;
}
.dialog-title{
  padding-top: 3px;
}

/*chrome y zafari*/

::ng-deep .mat-dialog-content::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::ng-deep .mat-dialog-content::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}
/*mozilla y edge*/

::ng-deep .mat-dialog-content::-moz-scrollbar {
  width: 8px;
}

::ng-deep .mat-dialog-content::-moz-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}

::ng-deep .mat-dialog-content::-moz-scrollbar-track {
  background-color: #F5F5F5;
}
/*edge*/
::ng-deep .mat-dialog-content {
  scrollbar-width: thin;
  scrollbar-color: #CCC #F5F5F5;
}

::ng-deep .mat-dialog-content::-ms-scrollbar {
  width: 8px;
}

::ng-deep .mat-dialog-content::-ms-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}

::ng-deep .mat-dialog-content::-ms-scrollbar-track {
  background-color: #F5F5F5;
}

/*para los matchip con estado*/

/*para los matchip con estado*/

.chip-aprobado {
  background-color: var(--primary) !important; /* Verde */
  color: white !important;
}

.chip-solicitado {
  background-color: var(--estadoEnProceso) !important; /* Amarillo */
  color: white !important;
}

.chip-rechazado {
  background-color: var(--estadoRechazado) !important; /* Rojo */
  color: white !important;
}
.chip-cerrado {
  background-color: var(--estadoCerrado) !important; /* Rojo */
  color: white !important;
}

.chip-pendiente {
  background-color: var(--estadoPendiente) !important; /* Rojo */
  color: white !important;
}

.chip-proceso {
  background-color: var(--estadoEnProceso) !important; /* Rojo */
  color: white !important;
}
.chip-terminado {
  background-color: var(--estadoTerminado) !important; /* Rojo */
  color: white !important;
}
.chip-anulado {
  background-color: var(--estadoAnulado) !important; /* Rojo */
  color: white !important;
}
.chip-pendiente-revision {
  background-color: var(--estadoRevision) !important; /* Rojo */
  color: white !important;
}
.chip-pendiente-reabrir {
  background-color: var(--estadoRevision) !important; /* Rojo */
  color: white !important;
}


.chip-estado-mesa-disponible{
  background-color: var(--estadoMesaDisponible) !important; /* Rojo */
  color: white !important;
}
.chip-estado-mesa-reservado{
  background-color: var(--estadoMesaReservado) !important; /* Rojo */
  color: #212121 !important;
}
.chip-estado-mesa-ocupado{
  background-color: var(--estadoMesaOcupado) !important; /* Rojo */
  color: #212121 !important;
}
.chip-estado-mesa-proceso{
  background-color: var(--estadoMesaProceso) !important; /* Rojo */
  color: white !important;
}


mat-chip-list mat-chip.chip-aprobado {
  background-color: var(--primary) !important; /* Verde */
  color: white;
}

mat-chip-list mat-chip.chip-solicitado {
  background-color: var(--estadoEnProceso) !important; /* Amarillo */
  color: white;
}

mat-chip-list mat-chip.chip-rechazado {
  background-color: var(--estadoRechazado) !important; /* Rojo */
  color: white;
}