:root {
  --primary: #2962FF;
  --white: #ffffff;
  --dark: #161616;
  --F4F4: #f4f4f4;
  --8888: #888888;
  --E0E0: #e0e0e0;

  --blueA700: #2962FF;
  --blueA400: #2979FF;
  --blueA200: #448AFF;

  --gray900: #212121;
  --gray700: #616161;
  --gray500: #9E9E9E;

  --red900: #B71C1C;
  --red600: #E53935;
  --red500: #F44336;

  /*estados mesa*/
  --estadoMesaDisponible: #3C79E0;
  --estadoMesaReservado: #888888;
  --estadoMesaOcupado: #c5c5c5c5;
  --estadoMesaProceso: #FFA726;

  /*estados pedido y producto*/

  --estadoPendiente: #3C79E0;
  --estadoEnProceso: #FFA726;
  --estadoTerminado: #66BB6A;
  --estadoEntregado: #EF5350;
  --estadoRechazado: #cb6866;
  --estadoAnulado: #616161;
  --estadoRevision: #FF7043;
  --estadoCerrado: #161616;

  --restablecer: #9E9E9E;

}
