.titulo-tab{
  margin-left: 3px;
}
.mat-expansion-panel{
  min-width: 100%;
}
.titulo-accordeon{
  margin-left: 5px;
  margin-top: 5px;
}

.container-table{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;  
  overflow-y: auto;
  max-height: 400px;
  margin-bottom: 5px;
  &--xs{
    max-height: 100%;
    margin-bottom: 30px;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CCC;
    border-radius: 10px;
  } 

}
.container-button-table{
  margin-top: 5px;
  margin-left: 40px;
  margin-bottom: 5px;
}
.floating-button {
  position: fixed;
  bottom: 40px;
  right: 15px;
  z-index: 200;
}

.floating-button-mini {
  position: fixed;
  bottom: 105px;
  right: 24px;
  z-index: 200; 

}
.floating-button-mini2 {
  position: fixed;
  bottom: 153px;
  right: 24px;
  z-index: 200;
}
.floating-button-mini3 {
  position: fixed;
  bottom: 40px;
  right: 24px;
  z-index: 200;
}

/*Estilos globales para el scrollbar chrome y safari*/
::ng-deep .custom-scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #f1f1f1;
}

::ng-deep .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/*browmozilla y browedge*/

::ng-deep .custom-scrollbar::-moz-scrollbar {
  width: 7px;
}

::ng-deep .custom-scrollbar::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::ng-deep .custom-scrollbar::-moz-scrollbar-track {
  background-color: #888;
}
::ng-deep .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

::ng-deep .custom-scrollbar::-ms-scrollbar {
  width: 7px;
}

::ng-deep .custom-scrollbar::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}


::ng-deep .custom-scrollbar::-ms-scrollbar-track {
  background-color: #888;
}

/*scrollable*/

/*chrome y zafari*/

::ng-deep .scrollable::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::ng-deep .scrollable::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}
/*mozilla y edge*/

::ng-deep .scrollable::-moz-scrollbar {
  width: 8px;
}

::ng-deep .scrollable::-moz-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}

::ng-deep .scrollable::-moz-scrollbar-track {
  background-color: #F5F5F5;
}
/*edge*/
::ng-deep .scrollable {
  scrollbar-width: thin;
  scrollbar-color: #CCC #F5F5F5;
}

::ng-deep .scrollable::-ms-scrollbar {
  width: 8px;
}

::ng-deep .scrollable::-ms-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}

::ng-deep .scrollable::-ms-scrollbar-track {
  background-color: #F5F5F5;
}