@font-face {
  font-display: swap;
  font-family: "IBMPlexSans";
  src: url("../../fonts/IBMPlexSans-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "IBMPlexSans Bold";
  src: url("../../fonts/IBMPlexSans-Bold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "IBMPlexSans Semi";
  src: url("../../fonts/IBMPlexSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "JuraRegular";
  src: url("../../fonts/Jura-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "JuraLight";
  src: url("../../fonts/Jura-Light.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "PoiretOne";
  src: url("../../fonts/PoiretOne-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "IBMPlexSans Light";
  src: url("../../fonts/PoiretOne-Regular.ttf") format("truetype");
}

:root {
  --IBMPlexSans: "IBMPlexSans";
  --IBMPlexSansBold: "IBMPlexSans Bold";
  --IBMPlexSansSemi: "IBMPlexSans Semi";
  --IBMPlexSansLight: "IBMPlexSans Light";
  --JuraRegular: "JuraRegular";
  --JuraLight: "JuraLight";
  --PoiretOne: "PoiretOne";
}
